/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { Img } from '@/components/blocks/MaterialImage';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { categoryContainerSX } from '@/components/content/Category/styles/container';
import { categoryHeadingSX } from '@/components/content/Category/styles/heading';
import { categoryImageSX } from '@/components/content/Category/styles/image';
import { categorySubHeadingSX } from '@/components/content/Category/styles/subHeading';
import { useCategory } from '@/data/Content/Category';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useUser } from '@/data/User';
import { ContentContext } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import { CategoryType } from '@/data/types/Category';
import { getContractIdFromContext } from '@/utils/getContractIdFromContext';
import { getHref_Category } from '@/utils/getHref_Category';
import { Grid, Paper, Typography } from '@mui/material';
import { FC, useContext, useEffect, useMemo } from 'react';

export const Category: FC<{
	id: ID;
	parent?: CategoryType;
	clickAction?: () => void;
}> = ({ id, clickAction, parent }) => {
	const { rawData, category, loading } = useCategory(id);
	const { user } = useUser();
	const router = useNextRouter();
	const { trail } = router.query;
	const contract = getContractIdFromContext(user?.context);
	const { onNotify } = useContext(ContentContext) as {
		onNotify: (id: ID, contract: string, category?: CategoryType) => void;
	};
	const routeUrl = useMemo(
		() => getHref_Category(category, parent, trail as string[]),
		[category, parent, trail]
	);

	useEffect(() => {
		if (onNotify && rawData) {
			onNotify(id, contract, category);
		}
	}, [loading, rawData, category]); // eslint-disable-line react-hooks/exhaustive-deps

	return loading ? (
		<ProgressIndicator />
	) : category ? (
		// TODO Hoist href to top level category object, presentation shouldn't have to handle this structure.
		<Linkable
			href={routeUrl}
			onClick={clickAction}
			data-testid={category.seo.href || ''}
			id={category.seo.href || ''}
		>
			<Paper sx={categoryContainerSX}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={5} sm={4} md={6}>
						<Img
							width="100%"
							alt={category.shortDescription}
							src={category.fullImage || category.thumbnail || ''}
							sx={categoryImageSX}
						/>
					</Grid>
					<Grid item xs={7} sm={6} px={2} py={1}>
						<Typography variant="h3" sx={categoryHeadingSX}>
							{category.name}
						</Typography>
						<Typography component="h4" variant="subtitle2" sx={categorySubHeadingSX}>
							{category.description}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Linkable>
	) : null;
};
